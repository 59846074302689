import { useContentful } from "react-contentful";
import { parseHeaderData } from "./helpers";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";
import Menu from "../Menu";
import { clsx } from "clsx";
import { Banner } from "../Banner/Banner";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { Button } from "../Button";

export const Header = ({
  banner,
  open,
  setOpen,
}: {
  banner?: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const dropdownRef = useRef(null); // Ref for the dropdown
  const buttonRef = useRef(null); // Ref for the button
  const { pathname } = useLocation();
  const { data, error, fetched, loading } = useContentful({
    contentType: "header",
  });

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !(
          !dropdownRef.current ||
          //@ts-ignore
          dropdownRef.current?.contains(event.target) ||
          !buttonRef.current ||
          //@ts-ignore
          buttonRef.current?.contains(event.target)
        )
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen]);

  useEffect(() => {
    setOpen(false);
  }, [pathname, setOpen]);

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { logo, links, smallLogo, products, signUp } = parseHeaderData(_data);

  const show = banner?.show;

  return (
    <>
      {show && <Banner banner={banner} />}
      <header
        className={clsx(
          "fixed flex items-center justify-between z-[50] h-[3.5rem] sm:h-16 rounded pl-6 pr-4 py-4 top-[1rem] left-[1rem] right-[1rem] bg-gradient-to-r from-[#0C0C0D]/95 to-[#0C0C0D]/70 via-[#0C0C0D]/75 backdrop-blur-xl",
          {
            "mx-[1rem] mt-[1rem] !sticky": show,
            "!rounded-b-none": open,
          },
        )}
      >
        <Link to={ROUTES.HOME} className="cursor-pointer">
          <img
            className="w-[6.5rem] sm:flex hidden"
            src={logo.imgSrc}
            alt={logo.title}
          />
          <img
            className="w-5 flex sm:hidden"
            src={smallLogo.imgSrc}
            alt={smallLogo.title}
          />
        </Link>
        <nav className="lg:flex sm:items-center space-x-3 hidden">
          <button
            ref={buttonRef}
            onClick={() => setOpen(!open)}
            className={clsx(
              "text-neutral-25 flex cursor-pointer items-center leading-[1.5rem] text-sm px-4 py-1 font-medium tracking-tighter rounded-sm transition-all hover:bg-neutral-500/50 duration-150",
              {
                "!bg-neutral-500/50": open,
              },
            )}
          >
            Products
            <ChevronDownIcon
              className={clsx(
                "w-5 ml-1 text-neutral-500 transition-all duration-200",
                {
                  "rotate-180 !text-white": open,
                },
              )}
            />
          </button>
          {links.map((link: any, index: number) => (
            <Link
              key={index}
              to={link.url}
              target={link.isBlank ? "_blank" : "_self"}
              className={clsx(
                "text-neutral-25 flex items-center hover:animate-container cursor-pointer leading-[1.5rem] text-sm px-4 py-1 font-medium tracking-tighter rounded-sm transition-all hover:bg-neutral-500/50 duration-150",
              )}
            >
              {link.label}
              {link.label === "Docs" && (
                <span className="ml-1.5 w-3 h-3 relative overflow-hidden">
                  <img
                    src="/icons/arrow-right-up-white.svg"
                    alt="arrow"
                    className="w-3 h-3 arrow-image"
                  />
                </span>
              )}
            </Link>
          ))}
        </nav>
        <div className="w-auto lg:w-[6.5rem] flex">
          {!signUp.hide && (
            <Link
              className="rounded-sm cursor-pointer transition-all duration-150 hover:bg-neutral-250 h-8 mr-2 lg:mr-0 text-xs flex ml-auto lg:text-sm px-3 lg:px-2 py-1 font-medium tracking-tighter bg-neutral-25 leading-[1.5rem]"
              to={signUp.url}
            >
              <span className="text-neutral-500 mr-1 hidden sm:block">[S]</span>{" "}
              {signUp.label}
            </Link>
          )}
          <Menu links={links} products={products} />
        </div>
      </header>
      {open && (
        <div
          ref={dropdownRef}
          className={clsx(
            "fixed transition-all hidden lg:block duration-200 z-20 top-[5rem] w-[calc(100%-32px)] right-[16px] rounded-b overflow-hidden left-[16px]",
            {
              "mx-[1rem] !sticky": show,
            },
          )}
        >
          <div className="grid grid-cols-4 w-full divide-neutral-850 divide-x border-t border-neutral-850">
            {products.map((p) => (
              <Link
                className="group p-2 text-neutral-25 bg-neutral-950 hover:bg-gradient-to-r hover:from-neutral-950 hover:via-neutral-850 hover:to-neutral-850"
                to={p.cta.url}
                key={p.title.replaceAll(" ", "-")}
              >
                <div className="h-[12rem] rounded overflow-hidden">
                  <img
                    src={p.image}
                    alt="product-image"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="flex flex-col p-4">
                  <div className="flex space-x-2">
                    <img src={p.icon} alt={p.title} className="h-4 mt-1" />
                    <h5 className="text-lg text-neutral-25">{p.title}</h5>
                  </div>
                  <p className="font-medium text-neutral-250 max-w-[15rem] tracking-tighter mt-2 text-sm">
                    {p.description}
                  </p>
                  <Button
                    mode="white"
                    className="mt-4 w-fit !py-2 hover:animate-container group-hover:!bg-primary-400 !border-none"
                  >
                    {p.cta.label}
                    <span className="ml-2 relative overflow-hidden h-3 w-3">
                      <img
                        src="/icons/arrow-right-up.svg"
                        className="h-3 w-3 arrow-image"
                        alt="arrow"
                      />
                    </span>
                  </Button>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
