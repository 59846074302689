import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { useContentful } from "react-contentful";
import { parseFeaturesData } from "./helpers";
import React, { useEffect } from "react";
import Spline from "@splinetool/react-spline";
import { clsx } from "clsx";
import { useInView } from "react-intersection-observer";
import { DivideSection } from "../../../components/DivideSection";

const letters = ["t", "q", "w", "e", "r"];

const triggerKeyUp = (key: string) => {
  const eventKeyUp = new KeyboardEvent("keyup", { key });
  const eventKeyDown = new KeyboardEvent("keydown", { key });
  document.dispatchEvent(eventKeyUp);
  document.dispatchEvent(eventKeyDown);
};

export const Features = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "features",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const {
    cta,
    title,
    description,
    pZeroDescription,
    pZeroTitle,
    cards,
    coinCards,
  } = parseFeaturesData(_data);

  return (
    <>
      <div className="flex flex-col border-t overflow-hidden border-neutral-850">
        <div className="flex flex-col sm:px-10 px-6">
          <div className="w-full border-r flex-col flex items-center py-8 sm:py-20 border-l border-neutral-850">
            <h3 className="text-neutral-25 px-4 tracking-tighter text-3xl leading-[1.75rem] text-center sm:text-[4rem] sm:leading-[4rem]">
              {title}
            </h3>
            <p className="sm:max-w-[32rem] px-4 tracking-tight sm:text-xl text-center font-medium text-neutral-250 mt-4">
              {description}
            </p>
            <Link
              to={cta.url}
              className="mt-6 w-fit mx-auto sm:mt-10 primary-button"
            >
              <Button
                mode="primary"
                className="hover:animate-container w-full sm:w-fit sm:px-5 sm:py-4 leading-none px-4 py-3"
              >
                <span>{cta.label}</span>
                <span className="sm:w-4 sm:h-4 w-3 h-3 ml-2 relative overflow-hidden">
                  <img
                    src={cta.icon}
                    alt="icon"
                    className="sm:w-4 sm:h-4 w-3 h-3 arrow-image"
                  />
                </span>
              </Button>
            </Link>
          </div>
          <div className="text-neutral-25 px-2 border-l border-r flex flex-col space-y-2 sm:space-y-0 border-neutral-850 sm:grid sm:gap-2 sm:grid-cols-4">
            {cards.map((card: any, index: number) => (
              <div
                className="flex text-neutral-25 bg-neutral-950 rounded sm:px-4 sm:py-12 p-6 flex-col items-center"
                key={index}
              >
                <div className="sm:w-24 sm:h-24 w-14 h-14 flex flex-none justify-center items-center">
                  <img src={card.icon} alt="icon" className="w-full h-full" />
                </div>

                <p className="sm:text-[1.75rem] leading-none tracking-tighter font-medium text-center mt-4 sm:mt-6">
                  {card.title}
                </p>
                <p className="sm:text-base text-sm tracking-tighter font-medium text-center mt-3 sm:mt-4 text-neutral-500">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <DivideSection side="full" isDark className="sm:hidden" />
      <div className="flex flex-col border-neutral-850">
        <div className="flex flex-col sm:px-10 px-6">
          <div className="w-full border-r sm:pt-[10rem] py-4 flex-col flex items-center border-l border-neutral-850">
            <h3 className="text-neutral-25 px-4 tracking-tighter text-3xl leading-[1.75rem] text-center sm:text-[4rem] sm:leading-[4rem]">
              {pZeroTitle}
            </h3>
            <p className="sm:max-w-[44rem] px-4 tracking-tight sm:text-xl text-center font-medium text-neutral-500 mt-4">
              {pZeroDescription}
            </p>
          </div>
          <div className="border-r border-l border-neutral-850 -mb-10 sm:-mb-20 overflow-hidden">
            <Spline
              className="xl:-mt-32 lg:-mt-28 scale-150 sm:scale-100"
              scene="https://prod.spline.design/B8ECA0rK94dX2miF/scene.splinecode"
            />
          </div>
        </div>
      </div>
      <div className="sm:px-10 relative px-6">
        <div className="flex md:flex-row flex-col w-full py-6 border-r border-l border-b border-neutral-850">
          <div className="md:w-1/2 z-10 w-full sticky md:top-[5rem] top-[4.5rem] flex md:h-[45rem]">
            <div className="hidden md:flex p-20 w-full">
              <Spline
                className="h-full w-full"
                scene="https://prod.spline.design/SfsIKwi2m73y8d7x/scene.splinecode"
              />
            </div>
            <div className="flex items-center w-full p-10 justify-center md:hidden bg-neutral-1000 h-[15.5rem]">
              <Spline
                className="h-full w-full"
                scene="https://prod.spline.design/SfsIKwi2m73y8d7x/scene.splinecode"
              />
            </div>
          </div>
          <div className="flex flex-col md:h-full  w-full md:w-1/2 md:items-center">
            {coinCards.map((card: any, index: number) => (
              <CoinCard
                card={card}
                letter={letters[index]}
                index={index}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const CoinCard = ({
  card,
  index,
  letter,
}: {
  card: any;
  index: number;
  letter: string;
}) => {
  const { inView, ref } = useInView();

  useEffect(() => {
    inView && triggerKeyUp(letter);
  }, [inView, letter]);

  return (
    <div className="flex flex-col md:px-[7.5rem] px-4 py-[9.5rem] md:py-[16rem] md:h-[45rem]">
      <h3
        className={clsx(
          "text-neutral-25 text-left tracking-tighter sm:max-w-md md:text-[4rem] text-3xl leading-[1.75rem] md:leading-[4rem]",
          {
            "md:!max-w-xs": index === 1,
          },
        )}
        ref={ref}
      >
        {card.title}
      </h3>
      <p className="mt-4 md:text-xl text-left text-neutral-250 md:max-w-md font-medium tracking-tighter">
        {card.description}
      </p>
    </div>
  );
};
