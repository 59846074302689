import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "../constants";
import { Home } from "../pages/Home/Home";
import { Layout } from "../components/Layout";
import { Blog } from "../pages/Blog/Blog";
import { Article } from "../pages/Article/Article";
import { TermsOfUse } from "../pages/TermsOfUse/TermsOfUse";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { Products } from "../pages/Products/Products";
import { Streaming } from "../pages/Products/Streaming";
import { Indexers } from "../pages/Products/Indexers";
import { ZeroAPIs } from "../pages/Products/ZeroAPIs";
import { ZeroAIAgents } from "../pages/Products/ZeroAIAgents";
import { Pricing } from "../pages/Pricing";
import { Integrations } from "../pages/Integrations";
import ScrollToTop from "./ScrollToTop";
import { NotFound } from "../pages/NotFound";

export const RoutesProvider = () => {
  return (
    <BrowserRouter basename={ROUTES.HOME}>
      <ScrollToTop />
      <Routes>
        <Route
          path={ROUTES.HOME}
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path={ROUTES.BLOG}
          element={
            <Layout>
              <Blog />
            </Layout>
          }
        />
        <Route
          path={`${ROUTES.BLOG}/:id`}
          element={
            <Layout>
              <Article />
            </Layout>
          }
        />
        <Route
          path={ROUTES.TERMS_OF_USE}
          element={
            <Layout>
              <TermsOfUse />
            </Layout>
          }
        />
        <Route
          path={ROUTES.PRIVACY_POLICY}
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route
          path={ROUTES.PRODUCTS}
          element={
            <Layout>
              <Products />
            </Layout>
          }
        />
        <Route
          path={ROUTES.PRODUCT_STREAMING}
          element={
            <Layout>
              <Streaming />
            </Layout>
          }
        />
        <Route
          path={ROUTES.PRODUCT_INDEX}
          element={
            <Layout>
              <Indexers />
            </Layout>
          }
        />
        <Route
          path={ROUTES.PRODUCT_API}
          element={
            <Layout>
              <ZeroAPIs />
            </Layout>
          }
        />
        <Route
          path={ROUTES.PRODUCT_ZERO_AI}
          element={
            <Layout>
              <ZeroAIAgents />
            </Layout>
          }
        />
        <Route
          path={ROUTES.PRICING}
          element={
            <Layout>
              <Pricing />
            </Layout>
          }
        />
        <Route
          path={ROUTES.INTEGRATIONS}
          element={
            <Layout>
              <Integrations />
            </Layout>
          }
        />
        <Route
          path="*"
          element={
            <Layout isDark>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
