import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { useContentful } from "react-contentful";
import { parseBlogData } from "../../Blog/helpers";
import { BlogCard } from "../../Blog/component/BlogCard";
import { useState } from "react";
import { clsx } from "clsx";
import { Dot } from "./Dot";
import { DotLabel } from "./DotLabel";

export const BlogPreview = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const { data, error, fetched, loading } = useContentful({
    contentType: "blog",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { previewArticles } = parseBlogData(_data);

  const disableButtons = previewArticles.length < 4;

  const handleNext = (cardsToShow: number) => {
    if (currentIndex < previewArticles.length / cardsToShow - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div className="flex flex-col border-neutral-850">
      <div className="sm:px-10 px-6 flex flex-col sm:grid sm:grid-cols-3 border-neutral-850 sm:gap-x-6">
        <div className="sm:p-10 p-4 border-l border-r sm:border-r-0 border-neutral-850 flex flex-col">
          <div className="flex items-center">
            <Dot />
            <DotLabel className="!text-neutral-25" label="blog" />
          </div>
          <div className="sm:text-5xl text-2xl text-neutral-25 sm:mt-6 mt-4 font-medium tracking-tighter sm:leading-[3rem]">
            Read more <br />
            about Project 0
          </div>
        </div>
        <div className="flex sm:p-6 w-full sm:col-span-2 sm:border-r border-neutral-850">
          <div className="flex w-full border-l border-neutral-850 sm:border-none items-center sm:w-auto sm:!ml-auto mt-auto font-semibold tracking-tight text-neutral-950">
            <Link
              to={ROUTES.BLOG}
              className="cursor-pointer text-neutral-25 whitespace-nowrap leading-none px-5 py-4 transition-all duration-150 hover:bg-neutral-850"
            >
              View all blog posts
            </Link>
            <div className="h-8 sm:block hidden w-[1px] bg-neutral-850 mx-6" />
            <div className="hidden sm:flex sm:items-center">
              <button
                onClick={() => handlePrev()}
                className="p-4 transition-all hover:bg-neutral-850 disabled:cursor-not-allowed duration-150"
                disabled={disableButtons}
              >
                <img
                  className="flex flex-none w-4 h-4"
                  src="/icons/arrow-left-white.svg"
                  alt="arrow-left"
                />
              </button>
              <button
                onClick={() => handleNext(3)}
                className="p-4 transition-all hover:bg-neutral-850 disabled:cursor-not-allowed duration-150 ml-2"
                disabled={disableButtons}
              >
                <img
                  className="transform rotate-180 w-4 h-4 flex flex-none"
                  src="/icons/arrow-left-white.svg"
                  alt="arrow-left"
                />
              </button>
            </div>
            <div className="flex items-center ml-auto border-r border-neutral-850 sm:hidden">
              <button
                onClick={() => handlePrev()}
                className="p-4 sm:bg-neutral-900"
              >
                <img
                  className="flex flex-none w-4 h-4"
                  src="/icons/arrow-left-white.svg"
                  alt="arrow-left"
                />
              </button>
              <button
                onClick={() => handleNext(1)}
                className="p-4 sm:bg-neutral-900"
              >
                <img
                  className="transform rotate-180 w-4 h-4 flex flex-none"
                  src="/icons/arrow-left-white.svg"
                  alt="arrow-left"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden border-t border-neutral-850 border-b mx-6 sm:mx-10">
        <div
          className="flex transition-transform duration-300"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {previewArticles.map((article: any, index: number) => (
            <div
              key={index}
              className={clsx(
                "sm:w-[calc((100%/3)-1rem)] w-full sm:mr-6 flex-shrink-0",
                {
                  "sm:!mr-0": (index + 1) % 3 === 0,
                },
              )}
            >
              <BlogCard isPreview article={article} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
