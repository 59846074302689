import React, { HTMLAttributes, JSXElementConstructor } from "react";
import { clsx } from "clsx";

type ButtonMode = "primary" | "white" | "black" | "gray";

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (args?: any) => void;
  color?: string;
  type?: "button" | "submit" | "reset";
  className?: string;
  icon?: JSXElementConstructor<any>;
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  mode?: ButtonMode;
}

export const Button = ({
  children,
  className,
  onClick,
  disabled,
  mode,
  type,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={clsx(
        className,
        "p-4 flex items-center transition-all tracking-tighter font-semibold duration-150 focus:outline rounded-sm",
        {
          "bg-primary-400 text-neutral-950 hover:bg-primary-550":
            mode === "primary",
        },
        {
          "bg-white border hover:bg-neutral-250 border-neutral-250 text-neutral-950":
            mode === "white",
        },
        {
          "bg-neutral-950 text-neutral-25 hover:bg-opacity-80":
            mode === "black",
        },
        {
          "bg-neutral-100 text-neutral-950 hover:!bg-primary-550/80":
            mode === "gray",
        },
      )}
      onClick={onClick}
      type={type}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
