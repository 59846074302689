import { useContentful } from "react-contentful";
import { Link, useParams } from "react-router-dom";
import { parseArticleData } from "./helpers";
import { ROUTES } from "../../constants";
import { CTASection } from "../blocks/CTASection/CTASection";
import { BLOCKS } from "@contentful/rich-text-types";
import dayjs from "dayjs";
import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ArticlePageLayout } from "../../components/ArticlePageLayout";

export const Article = () => {
  const { id } = useParams();

  const { data, error, fetched, loading } = useContentful({
    contentType: "article",
    query: {
      "sys.id": `${id || ""}`,
    },
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { title, date, image, content } = parseArticleData(_data);

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        const videoUrl = node.data.target.fields.videoUrl;
        if (videoUrl) {
          return (
            <div className="video-embed my-4">
              <iframe
                src={videoUrl}
                title="Video Preview"
                allowFullScreen
                className="w-full aspect-video"
              />
            </div>
          );
        }
        return null;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const { file, title } = node.data.target.fields;
        return (
          <img
            className="border rounded"
            src={file.url}
            alt={title || "Embedded asset"}
          />
        );
      },
    },
  };

  const Hero = () => (
    <>
      <div className="py-2 px-4">
        <Link
          to={ROUTES.BLOG}
          className="items-center hover:bg-neutral-250/80 p-4 w-fit flex"
        >
          <img
            className="w-3.5 h-3.5 mr-1"
            src="/icons/arrow-left.svg"
            alt="arrow-left"
          />
          <span className="font-semibold sm:text-base text-sm sm:leading-[1.5rem]">
            Back to blog
          </span>
        </Link>
      </div>
      <h3 className="sm:text-[4rem] text-[2.5rem] sm:leading-[4rem] leading-[2.5rem] text-neutral-950 text-center mt-4 sm:mt-8">
        {title}
      </h3>
      <p className="text-neutral-500 font-medium tracking-tighter sm:text-xl text-center mt-4 sm:mt-6">
        {dayjs(new Date(date)).format("MMM D, YYYY")}
      </p>
    </>
  );

  return (
    <>
      <ArticlePageLayout hero={<Hero />} image={image}>
        {documentToReactComponents(content, options)}
      </ArticlePageLayout>
      <CTASection />
    </>
  );
};
