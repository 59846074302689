import { useContentful } from "react-contentful";
import { parseCTASectionData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { clsx } from "clsx";

export const CTASection = ({ isHome }: { isHome?: boolean }) => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "ctaSection",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { image, cta, title, description, mobileImage, bgImage, darkBgImage } =
    parseCTASectionData(_data);

  return (
    <div className="px-2 pb-2">
      <div
        className={clsx(
          "rounded flex flex-col bg-contain bg-no-repeat overflow-hidden w-full",
          {
            "bg-neutral-25": isHome,
            "bg-neutral-950": !isHome,
          },
        )}
        style={{
          backgroundImage: `url('${isHome ? bgImage.imgSrc : darkBgImage}')`,
        }}
      >
        <div className="flex flex-col pt-8 px-4 sm:px-10 sm:pt-20">
          <h3
            className={clsx(
              "sm:text-[4rem] sm:leading-[4rem] tracking-tighter text-left sm:text-center sm:mx-auto max-w-[19rem] sm:!max-w-lg text-[2.5rem] leading-[2.5rem]",
              {
                "text-neutral-950": isHome,
                "text-neutral-25": !isHome,
              },
            )}
          >
            {title}
          </h3>
          <p
            className={clsx(
              "sm:text-xl text-left sm:mx-auto sm:text-center font-medium tracking-tighter mt-2 sm:mt-4",
              {
                "text-neutral-950": isHome,
                "text-neutral-25": !isHome,
              },
            )}
          >
            <span className="hidden sm:block">
              Experience complete blockchain data infrastructure platform.{" "}
              <br /> Sign up today to get started for free or explore our
              flexible pricing options.
            </span>
            <span className="block sm:hidden">{description}</span>
          </p>
          <Link
            to={cta.url}
            className="mt-6 primary-button mr-auto sm:mx-auto sm:text-xl w-fit"
          >
            <Button
              mode={isHome ? "black" : "primary"}
              className="justify-between hover:animate-container sm:text-xl w-fit !px-4 !py-3 sm:!px-5 sm:py-4"
            >
              <span className="hidden sm:block">{cta.label}</span>
              <span className="block sm:hidden">Sign up</span>
              <span className="sm:w-4 sm:h-4 w-3 h-3 relative overflow-hidden ml-2">
                <img
                  className="sm:w-4 sm:h-4 w-3 h-3 arrow-image"
                  src={
                    isHome
                      ? "/icons/arrow-right-up-white.svg"
                      : "/icons/arrow-right-up.svg"
                  }
                  alt={cta.image.title}
                />
              </span>
            </Button>
          </Link>
        </div>
        <div className="block sm:hidden ml-4 mt-10">
          <img src={mobileImage.imgSrc} alt={mobileImage.title} />
        </div>
        <div className="sm:block hidden px-10 mt-20 rounded-t-2xl overflow-hidden">
          <img
            src={image.imgSrc}
            alt={image.title}
            className="rounded-t-2xl border-r border-l border-t"
          />
        </div>
      </div>
    </div>
  );
};
