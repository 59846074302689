import { PricingCardProps, SubscriptionPlan } from "../types";
import { clsx } from "clsx";
import { Toggle } from "../../../components/Toggle";
import { Link } from "react-router-dom";
import { useState } from "react";

export const SmallPricingCard = ({
  name,
  price,
  description,
  isLast,
}: PricingCardProps) => {
  const [yearly, setYearly] = useState<boolean>(true);

  const isVoyager = name === SubscriptionPlan.Voyager;

  const plan = isVoyager && yearly ? SubscriptionPlan.VoyagerYearly : name;

  return (
    <div
      className={clsx(
        "flex flex-col border-r sm:border-r-0 flex-none sm:px-6 p-4 w-[9rem] sm:w-auto sm:py-0 sm:items-center text-neutral-950",
        {
          "!border-r-0": isLast,
        },
      )}
    >
      <div className="font-semibold capitalize text-left sm:text-center tracking-tighter text-sm sm:text-base mb-4 sm:mb-5">
        {name === SubscriptionPlan.Free ? "Explorer" : name}
      </div>
      <div className="font-medium text-left sm:text-center tracking-tighter mb-3">
        <span className="text-2xl sm:text-3xl">
          {isVoyager && yearly ? "$180" : price}
        </span>
        {price.includes("$") && price !== "$3/GB" && (
          <span className="ml-2 sm:!text-bae mb-3">/mo</span>
        )}
      </div>
      <div className="font-medium text-left sm:text-center h-6 flex items-center text-neutral-500 sm:text-base tracking-tight mb-4 sm:mb-8">
        {isVoyager && (
          <Toggle
            className="!mb-0 mr-2"
            enabled={yearly}
            setEnabled={setYearly}
          />
        )}

        <span
          className={clsx("text-sm sm:text-base text-left sm:text-center", {
            "text-neutral-950": isVoyager && yearly,
          })}
        >
          {description}
        </span>
      </div>
      <Link
        className={clsx(
          "h-10 hover:animate-container hover:bg-neutral-250 border rounded-sm sm:text-base transition-all duration-150 font-semibold border-neutral-250 w-full flex items-center justify-center",
        )}
        target="_blank"
        to={
          name === "trailblazer"
            ? "https://forms.fillout.com/t/tEWvudSA3vus"
            : `https://app.projectzero.io/settings?tab=billing&plan=${plan}`
        }
      >
        {name === "trailblazer" ? (
          <span className="sm:mr-2 mr-1 flex">
            Contact <span className="ml-1 hidden sm:block">us</span>
          </span>
        ) : (
          <span className="sm:mr-2 mr-1 flex">
            Select <span className="ml-1 hidden sm:block">plan</span>
          </span>
        )}

        <span className="relative overflow-hidden w-3 h-3">
          <img
            className="w-3 h-3 arrow-image"
            src="/icons/arrow-right-up.svg"
            alt="arrow-right-up"
          />
        </span>
      </Link>
    </div>
  );
};
