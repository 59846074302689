import { CTASection } from "../blocks/CTASection/CTASection";
import { HeroSection } from "./components/HeroSection";
import { DivideSection } from "../../components/DivideSection";
import { WhyWeDifferent } from "./components/WhyWeDifferent";
import { Features } from "./components/Features";
import { BigDivideSection } from "../../components/BigDivideSection";
import { Benefits } from "./components/Benefits";
import { BlogPreview } from "./components/BlogPreview";

export const Home = () => {
  return (
    <div className="bg-neutral-1000">
      <HeroSection />
      <DivideSection isDark side="full" />
      <WhyWeDifferent />
      <DivideSection isDark side="full" />
      <Benefits />
      <div className="sm:block hidden">
        <BigDivideSection isDark side="full" />
      </div>
      <Features />
      <BlogPreview />
      <div className="sm:block hidden">
        <BigDivideSection isDark side="full" />
      </div>
      <div className="block sm:hidden">
        <DivideSection isDark side="full" />
      </div>
      <CTASection isHome />
    </div>
  );
};
