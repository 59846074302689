import { useContentful } from "react-contentful";
import { parseWhyWeDifferentData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import React from "react";

export const WhyWeDifferent = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "whyWeDifferent",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { cta, title, description, cards } = parseWhyWeDifferentData(_data);

  return (
    <div className="flex text-neutral-25 border-neutral-850 px-6 sm:px-10">
      <div className="flex sm:flex-row flex-col w-full items-center border-r border-l border-neutral-850">
        <div className="flex flex-col sm:w-2/5 w-full sm:p-10">
          <h3 className="sm:text-[4rem] text-center px-1 sm:px-0 mx-auto sm:mr-auto sm:text-left text-3xl leading-[1.75rem] sm:leading-[4rem] text-neutral-25 max-w-[14rem] sm:max-w-[28rem]">
            {title}
          </h3>
          <p className="text-neutral-250 text-center mx-auto px-4 sm:pl-0 sm:mr-auto sm:text-left sm:text-xl sm:pr-6 mt-4 tracking-tighter sm:mt-6 font-medium sm:max-w-[32rem]">
            {description}
          </p>
          <Link
            to={cta.url}
            className="mt-6 w-fit sm:mr-auto sm:ml-0 mx-auto sm:mt-10 primary-button"
          >
            <Button
              mode="primary"
              className="hover:animate-container w-full sm:w-fit sm:px-5 sm:py-4 px-4 py-3"
            >
              <span>{cta.label}</span>
              <span className="sm:w-4 sm:h-4 w-3 h-3 ml-2 relative overflow-hidden">
                <img
                  src={cta.icon}
                  alt="icon"
                  className="sm:w-4 sm:h-4 w-3 h-3 arrow-image"
                />
              </span>
            </Button>
          </Link>
        </div>
        <div className="sm:grid sm:grid-cols-3 mt-14 px-4 sm:pl-0 sm:mt-0 sm:w-3/5 w-full h-full flex flex-col space-y-2 sm:space-y-0 sm:pr-10 sm:gap-2">
          {cards.map((card: any, index: number) => (
            <div
              className="flex text-neutral-25 h-full rounded bg-neutral-950 sm:p-2 p-6 flex-col items-center justify-center"
              key={index}
            >
              <img
                src={card.icon}
                className="h-14 w-14 sm:h-20 sm:w-20"
                alt="card-icon"
              />
              <p className="sm:text-xl px-10 sm:h-6 font-medium text-center mt-4 sm:mt-6">
                {card.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
