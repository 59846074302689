import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import { Button } from "../../components/Button";

export const NotFound = () => {
  return (
    <div className="flex flex-col bg-neutral-1000 pt-10 items-center">
      <div className="flex flex-col items-center pt-20 lg:pb-10 mx-auto px-4">
        <h3 className="text-neutral-25 text-center text-[2.5rem] leading-[2.5rem] lg:text-[5rem] lg:leading-[5rem]">
          Zero page found
        </h3>
        <p className="text-neutral-500 text-center text-sm lg:text-lg mt-2">
          We could not find the page you were looking for
        </p>
        <Link
          to={ROUTES.HOME}
          className="hover:animate-container w-fit lg:mt-10 mt-6"
        >
          <Button mode="primary" className="leading-none">
            <span>Back home</span>
            <span className="w-3 h-3 ml-2 relative overflow-hidden">
              <img
                src="/icons/arrow-right-up.svg"
                alt="arrow"
                className="arrow-image h-3 w-3"
              />
            </span>
          </Button>
        </Link>
      </div>
      <img src="/bg/not-found.png" alt="not-found" className="w-full h-auto" />
    </div>
  );
};
