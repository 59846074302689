import { useContentful } from "react-contentful";
import { parseBenefitsData } from "./helpers";
import React from "react";
import { clsx } from "clsx";
import { DivideSection } from "../../../components/DivideSection";

const agentImgSrc = [
  "/ai-launchpad/illustration-1.png",
  "/ai-launchpad/illustration-2.png",
  "/ai-launchpad/illustration-3.png",
];

export const Benefits = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "benefits",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { cards, aiAgentTitle, aiAgentsCards, video } =
    parseBenefitsData(_data);

  return (
    <>
      <div className="border-t sm:border-b sm:px-10 mx-6 sm:mx-0 border-neutral-850 flex flex-col">
        <div className="border-r border-l flex flex-col items-center text-center w-full text-neutral-25 border-neutral-850 px-4 py-8 sm:px-10 sm:py-12">
          <h3 className="sm:text-[4rem] w-full text-3xl leading-[1.75rem] sm:leading-[4rem] sm:max-w-3xl">
            Benefits of <br />
          </h3>{" "}
          <h3 className="sm:text-[4rem] justify-center mt-1 w-full sm:mt-0 flex items-center sm:h-[4rem] h-[1.75rem] text-3xl leading-[1.75rem] sm:leading-[4rem] sm:max-w-3xl">
            AI{" "}
            <span className="inline-flex rounded-full sm:mb-1 mx-2 overflow-hidden">
              <img
                className="sm:h-14 h-8"
                src="/icons/avatar-bg.png"
                alt="avatar"
              />
            </span>{" "}
            Agents
          </h3>
          <p className="sm:text-xl text-neutral-250 tracking-tighter font-medium leading-none sm:mt-6 mt-4">
            Powered by Project Zero
          </p>
        </div>
      </div>
      <div className="sm:border-b sm:px-10 px-6 flex flex-col border-neutral-850 sm:grid sm:grid-cols-2 sm:gap-x-6">
        {cards.slice(0, 2).map((card: any, index: number) => (
          <React.Fragment key={index}>
            <Benefit benefit={card} isReversed={index === 1} isSmall />
            <div className="flex sm:hidden border-t bg-neutral-1000 h-6 w-auto border-l border-r border-neutral-850" />
          </React.Fragment>
        ))}
      </div>
      <MobileDivide />
      <div className="border-b sm:px-10 px-6 flex flex-col border-neutral-850 sm:grid sm:grid-cols-3 sm:gap-x-6">
        {cards.slice(2).map((card: any, index: number) => (
          <React.Fragment key={index}>
            <Benefit benefit={card} isReversed={index === 1} />
            {index + 1 !== cards.slice(2).length && (
              <div className="flex sm:hidden border-t bg-neutral-1000 h-6 w-auto border-l border-r border-neutral-850" />
            )}
          </React.Fragment>
        ))}
      </div>
      <DivideSection isDark side="full" />
      <div className="flex flex-col border-t border-b border-neutral-850 bg-neutral-1000/5">
        <div className="relative sm:mx-10 sm:h-[100rem] h-[25rem] mb-[-18rem] sm:mb-[-75rem] bg-neutral-1000/5 overflow-hidden">
          <video
            autoPlay
            loop
            muted
            playsInline
            className={clsx(
              "flex h-full sm:border-l w-full border-r border-neutral-850 object-contain absolute top-[-14rem] bg-neutral-[#060808] sm:top-[-55rem] rounded z-0",
            )}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="flex flex-col z-20 items-center sm:mx-10 mx-6 bg-neutral-1000/5 sm:pb-20 sm:border-l justify-center sm:border-r border-neutral-850">
          <div className="w-fit border-gradient sm:px-3 sm:py-2 px-2 py-1 sm:mb-6 mb-4">
            <h5 className="sm:text-xl !leading-none text-neutral-25">
              Coming soon
            </h5>
          </div>
          <h3 className="text-neutral-25 sm:text-[4rem] text-3xl leading-[1.75rem] sm:leading-[4rem] text-center max-w-[10rem] sm:max-w-xs">
            {aiAgentTitle}
          </h3>
        </div>
        <div className="flex flex-col mt-6 z-20 sm:mt-auto">
          {aiAgentsCards.map((card: any, index: number) => (
            <React.Fragment key={index}>
              <AgentCard
                card={card}
                isLast={index + 1 === aiAgentsCards.length}
                index={index}
              />
              {index + 1 !== aiAgentsCards.length && (
                <div className="flex bg-neutral-1000 sm:h-10 h-6 w-auto sm:mx-10 mx-6 border-l border-r border-neutral-850" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

const Benefit = ({
  benefit,
  isReversed,
  isSmall,
}: {
  benefit: any;
  isSmall?: boolean;
  isReversed?: boolean;
}) => (
  <>
    <div
      className={clsx(
        "bg-neutral-950 border-neutral-850 flex sm:flex-col divide-y divide-neutral-850 sm:divide-y-0 flex-col-reverse border-l border-r",
        { "sm:!flex-col-reverse": isReversed },
      )}
    >
      <img
        className="sm:h-[18.75rem] object-cover h-[12.5rem] flex flex-none"
        src={benefit.image.imgSrc}
        alt={benefit.image.title}
      />
      <div className="sm:grid sm:grid-cols-2 p-4 sm:p-8">
        <div
          className={clsx("flex flex-col", {
            "col-span-2": !isSmall,
            "col-span-1": isSmall,
          })}
        >
          <h3 className="text-neutral-25 text-xl sm:text-[1.75rem] tracking-tighter leading-none">
            {benefit.title}
          </h3>
          <p className="mt-2 text-sm sm:text-base text-neutral-500 font-medium tracking-tighter">
            {benefit.description}
          </p>
        </div>
      </div>
    </div>
  </>
);

const MobileDivide = () => (
  <div className="sm:flex hidden items-center w-full border-neutral-850 border-b">
    <div className="flex flex-none h-6 w-10" />
    <div className="w-full flex h-6 border-l border-neutral-850 border-r" />
    <div className="flex flex-none h-6 w-10" />
  </div>
);

const AgentCard = ({
  card,
  index,
  isLast,
}: {
  card: any;
  index: number;
  isLast: boolean;
}) => {
  const isOdd = (index + 1) % 2 === 0;
  return (
    <div
      className={clsx(
        "sm:grid sm:grid-cols-2 border-t border-b sm:gap-x-6 sm:px-10 px-6 border-neutral-850 bg-neutral-1000 flex",
        {
          "flex-col": !isOdd,
          "flex-col-reverse": isOdd,
          "border-b-0": isLast,
        },
      )}
    >
      {!isOdd && (
        <img
          className="border-r border-l object-cover border-neutral-850"
          src={agentImgSrc[index]}
          alt={`ai-agent-picture-${index + 1}`}
        />
      )}
      <div
        className={clsx(
          "flex flex-col border-r border-l border-neutral-850 sm:px-10 px-4 py-6 sm:py-1",
        )}
      >
        <div className="my-auto">
          <img
            src={card.icon}
            alt="card-icon"
            className="sm:w-10 sm:h-10 h-6 w-6"
          />

          <h5 className="font-medium text-2xl tracking-tighter mt-4 sm:mt-10 text-neutral-25 sm:text-5xl">
            {card.title}
          </h5>
          <p className="font-medium text-neutral-500 max-w-[27.5rem] mt-1 tracking-tighter sm:text-xl">
            {card.description}
          </p>
        </div>
      </div>
      {isOdd && (
        <img
          className="border-l border-r object-cover sm:border-neutral-850"
          src={agentImgSrc[index]}
          alt={`ai-agent-picture-${index + 1}`}
        />
      )}
    </div>
  );
};
