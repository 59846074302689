import { useContentful } from "react-contentful";
import { parseHeroSectionData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { VideoBackground } from "../../../components/VideoBackground";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import { gsap } from "gsap";
import { clsx } from "clsx";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { useEffect, useRef, useState } from "react";
import { ROUTES } from "../../../constants";

const words = ["think", "act", "evolve"];
const widths = [240, 154, 275];
const mobileWidths = [120, 77, 138];

export const HeroSection = () => {
  const [width, setWidth] = useState(widths[0]);
  const [mobileWidth, setMobileWidth] = useState(mobileWidths[0]);
  const textRef = useRef(null);
  const mobileTextRef = useRef(null);
  const indexRef = useRef(0);
  const mobileIndexRef = useRef(0);
  const { data, error, fetched, loading } = useContentful({
    contentType: "heroSection",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (indexRef.current + 1) % words.length;
      const nextIndexMobile = (mobileIndexRef.current + 1) % words.length;

      gsap.to(textRef.current, {
        y: "-100%",
        opacity: 0,
        duration: 0.7,
        ease: "power2.out",
        onComplete: () => {
          indexRef.current = nextIndex;

          setWidth(widths[nextIndex]);

          (textRef.current as unknown as HTMLElement).innerHTML =
            words[nextIndex];

          gsap.fromTo(
            textRef.current,
            { y: "100%", opacity: 0 },
            { y: "0%", opacity: 1, duration: 0.7, ease: "power2.inOut" },
          );
        },
      });
      gsap.to(mobileTextRef.current, {
        y: "-100%",
        opacity: 0,
        duration: 0.7,
        ease: "power2.out",
        onComplete: () => {
          mobileIndexRef.current = nextIndexMobile;

          setMobileWidth(mobileWidths[nextIndexMobile]);
          (mobileTextRef.current as unknown as HTMLElement).innerHTML =
            words[nextIndexMobile];

          gsap.fromTo(
            mobileTextRef.current,
            { y: "100%", opacity: 0 },
            { y: "0%", opacity: 1, duration: 0.7, ease: "power2.inOut" },
          );
        },
      });
    }, 2700);

    return () => clearInterval(interval);
  }, []);

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { cta, video, badgeText, backedBy, productHunt } =
    parseHeroSectionData(_data);

  const content = () => (
    <div className="flex flex-col sm:mb-6 sm:mt-[6rem] mt-[10rem] z-10 sm:pl-10 px-4 sm:space-y-10">
      <h5 className="rounded-full mx-auto leading-none sm:px-5 sm:py-2 px-3 py-1 w-fit bg-neutral-900/60 backdrop-blur-xl text-xs text-neutral-25 sm:text-xl">
        <span className="sm:block hidden">{badgeText}</span>
        <span className="block sm:hidden">
          {badgeText.replace("for Web3 and AI", "")} platform
        </span>
      </h5>
      <h3 className="sm:text-[5rem] hidden sm:block mx-auto mt-4 text-center text-[2.5rem] max-w-4xl sm:leading-[5rem] leading-[2.5rem]">
        Empower AI Agents to
      </h3>
      <h3 className="sm:text-[5rem] sm:hidden mx-auto mt-4 text-center text-[2.5rem] max-w-4xl sm:leading-[5rem] leading-[2.5rem]">
        Empower AI
      </h3>
      <h3 className="sm:text-[5rem] flex sm:hidden mx-auto mt-4 text-center text-[2.5rem] max-w-4xl sm:leading-[5rem] leading-[2.5rem]">
        Agents to
        <span
          className={clsx(
            "sm:px-6 ml-2 leading-none switch-container border-gradient overflow-hidden justify-center flex sm:hidden flex-none mr-2",
          )}
          style={{ width: `${mobileWidth}px` }}
        >
          <span
            className="text-gradient inline-flex switch-text text-center justify-center"
            ref={mobileTextRef}
          >
            {words[0]}
          </span>
        </span>
      </h3>
      <h3
        className="sm:text-[5rem] flex mx-auto mt-4 text-center sm:!mt-6 text-[2.5rem] max-w-4xl sm:leading-[5rem] leading-[2.5rem]"
        style={{ transition: "all 2s" }}
      >
        <span
          className={clsx(
            "sm:px-6 leading-none switch-container border-gradient overflow-hidden justify-center hidden sm:flex flex-none mr-4",
          )}
          style={{ width: `${width}px` }}
        >
          <span
            className="text-gradient inline-flex switch-text text-center justify-center"
            ref={textRef}
          >
            {words[0]}
          </span>
        </span>{" "}
        <span className="whitespace-nowrap">in real-time</span>
      </h3>
      <div className="flex sm:flex-row sm:items-center !mt-6 sm:!mt-10 mx-auto space-x-2 sm:space-x-4">
        <Link
          to={cta.url}
          className="sm:w-full w-fit h-12 sm:h-[3.5rem] flex flex-none max-w-none sm:max-w-[13.8rem] primary-button"
        >
          <Button
            mode="white"
            className="justify-between whitespace-nowrap sm:px-5 sm:py-4 px-4 py-2 sm:text-xl text-base hover:animate-container group w-full"
          >
            <span className="sm:block hidden">Try it now for free!</span>
            <span className="block sm:hidden">Try it now!</span>
            <span className="sm:w-4 sm:h-4 w-3 h-3 ml-2 relative overflow-hidden">
              <img
                src={cta.image.imgSrc}
                alt={cta.image.title}
                className="sm:w-4 sm:h-4 w-3 h-3 object-cover arrow-image"
              />
            </span>
          </Button>
        </Link>
        <Link
          to={ROUTES.PRODUCT_ZERO_AI}
          className="text-neutral-25 h-12 whitespace-nowrap sm:text-xl px-4 sm:px-5 max-w-none w-fit hover:animate-container rounded transition-all duration-150 hover:bg-neutral-500/50 tracking-tighter font-semibold sm:max-w-[16.25rem] flex-none sm:h-[3.5rem] sm:w-full flex items-center justify-center"
        >
          <span className="sm:block hidden">Explore Zero AI agents</span>
          <span className="block sm:hidden">Explore AI agents</span>
          <span className="sm:w-4 sm:h-4 w-3 h-3 relative ml-2 overflow-hidden">
            <img
              src="/icons/arrow-right-up-white.svg"
              alt="arrow-right-up"
              className="sm:w-4 sm:h-4 w-3 h-3 object-cover arrow-image"
            />
          </span>
        </Link>
      </div>
      <div className="flex sm:flex-row flex-col items-center !mt-[10rem] sm:!mt-[11.5rem] justify-between">
        <div className="flex items-center w-full mb-6 sm:mb-0 sm:w-auto overflow-auto space-x-4">
          <span className="text-neutral-500 whitespace-nowrap text-sm sm:text-base font-medium">
            Backed by:
          </span>
          {backedBy.map(({ label, icon, link }: any, index: number) => (
            <Link
              to={link}
              className="flex space-x-1 flex-nowrap items-center"
              key={index}
            >
              <img src={icon} alt="icon" className="sm:w-4 sm:h-4 w-3" />
              <span className="font-medium text-sm sm:text-base whitespace-nowrap text-neutral-25">
                {label}
              </span>
              <ChevronRightIcon className="sm:w-6 w-4 !ml-0 sm:ml-1 flex flex-none text-neutral-500" />
            </Link>
          ))}
        </div>

        <div className="flex items-center space-x-2">
          {!!productHunt.length &&
            productHunt.map((link: any, index: number) => (
              <Link
                to={link.url}
                target={link.isBlank ? "_blank" : "_self"}
                key={index}
              >
                <img
                  className="sm:w-[15.625rem] sm:h-[3.375rem]"
                  src={link.imgSrc}
                  alt={`Project&#0032;Zero - Extract&#0044;&#0032;transform&#0032;&#0038;&#0032;stream&#0032;Blockchain&#0032;data | Product Hunt ${index}`}
                />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="px-2 bg-neutral-1000 pt-2">
      <VideoBackground
        childrenClass="bg-gradient-to-b from-[#0C0C0D]/0 to-[#0C0C0D] via-[#0C0C0D]/20"
        className="border-t border-r border-l !block rounded-tr rounded-tl border-neutral-850"
        videoSrc={video.url}
        videoClass="bg-neutral-950"
        layoutClass="bg-neutral-950"
      >
        {content()}
      </VideoBackground>

      <div className="sm:block hidden relative -mt-6 z-20 bg-neutral-950 border-l rounded-b border-r border-b border-neutral-850 pt-6 px-10">
        <div className="border-t border-neutral-850 py-[10rem]">
          <ReactCompareSlider
            handle={<CustomSlider />}
            position={25}
            className="slider"
            changePositionOnHover={true}
            onlyHandleDraggable={false}
            itemOne={
              <ReactCompareSliderImage
                src="/bg/before-desktop-min.png"
                alt="Image one"
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src="/bg/after-desktop-min.png"
                alt="Image two"
              />
            }
          />
        </div>
      </div>
      <div className="block sm:hidden bg-neutral-950 border-l border-r border-b border-neutral-850 pt-20 px-6">
        <div className="pb-10">
          <ReactCompareSlider
            id="mobile-slider"
            style={{ touchAction: "auto" }}
            handle={<CustomSlider />}
            position={20}
            className="mobile-slider"
            aria-valuemax={90}
            onlyHandleDraggable={true}
            itemOne={
              <ReactCompareSliderImage
                src="/bg/before-mobile-min.png"
                alt="Image one"
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src="/bg/after-mobile-min.png"
                alt="Image two"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

const CustomSlider = () => (
  <img className="h-full object-cover" src="/icons/slider.svg" alt="slider" />
);
